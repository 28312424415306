import { css } from "styled-components";

import { colors } from "./colors";

import { ease } from "@/styles/ease";

export const DarkTheme = (content: string) => css`
  [data-theme="dark"] & {
    ${content}
  }
`;
export const TruncateText = ({ maxLines }) => css`
  text-overflow: ellipsis;

  ${maxLines
    ? css`
        -webkit-line-clamp: ${maxLines};
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      `
    : css`
        overflow: hidden;
        white-space: nowrap;
      `}
`;

//Hover states only on non-touch devices
export const Hover = (content: string, hoverEl?: HTMLElement) => {
  if (hoverEl) {
    return css`
      @media (any-hover: hover) {
        ${hoverEl}:hover & {
          ${content}
        }
      }
    `;
  }

  return css`
    @media (any-hover: hover) {
      &:hover {
        ${content}
      }
    }
  `;
};

export const RemoveScrollBar = css`
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const srOnly = css`
  &:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

enum Breakpoints {
  XS = "640px",
  SM = "1024px",
  MD = "1440px",
  LG = "1920px",
}

export const MQ: any = Object.keys(Breakpoints).reduce((acc, bp) => {
  acc[`${bp}`] = (...args) => css`
    @media (min-width: ${Breakpoints[`${bp}`]}) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const MainNavSectionGrid = css`
  --content-width: calc(
    var(--mainNav-panel-section-width) - var(--mainNav-margin)
  );
  display: grid;
  align-items: center;
  grid-template-columns: var(--mainNav-width) var(--content-width) var(
      --mainNav-margin
    );
  justify-items: stretch;
  width: 100%;
`;

export const center = css`
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

export const Indicator = css`
  position: relative;

  &:before {
    --indicator-height: calc(var(--mainNav-avatar-size) + 1rem);

    content: "";
    background-color: ${colors.white.default};
    border: 2px solid ${colors.tangerine.normal.hex};
    border-radius: 100%;
    display: block;
    height: calc(var(--mainNav-avatar-size) + 0.5rem);
    left: 50%;
    position: absolute;
    top: 50%;
    transition: all 0.3s ${ease.inOutCubic};
    transform: translate(-50%, -50%);
    width: calc(var(--mainNav-avatar-size) + 0.5rem);
    z-index: 0;
  }
`;

export const IndicatorPanelOpen = css`
  position: relative;

  &:before {
    --indicator-height: calc(var(--mainNav-avatar-size) + 1rem);

    content: "";
    background-color: ${colors.white.default};
    border: 2px solid ${colors.grey.normal.hex};
    border-radius: 100%;
    display: block;
    height: calc(var(--mainNav-avatar-size) + 0.5rem);
    left: 50%;
    position: absolute;
    top: 50%;
    transition: all 0.3s ${ease.inOutCubic};
    transform: translate(-50%, -50%);
    width: calc(var(--mainNav-avatar-size) + 0.5rem);
    z-index: 0;
  }
`;

export const RandomColor = css`
  ${({ randomColor }) => {
    if (!randomColor) return;
    return css`
      background-color: ${randomColor.background};
      color: ${randomColor.text};
    `;
  }}
`;
