// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { Provider as AuthProvider } from "next-auth/client";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useEffect } from "react";

import Meta from "@/app/Meta";
import EmptyLayout from "@/layouts/EmptyLayout";
import graphicsService from "@/services/GraphicsService";

import DatepickerStyles from "@/styles/datepicker";
import GlobalStyle from "@/styles/global";

import Clipaths from "~/public/assets/icons/Clippaths";

/*
// This will be removed in a week from 2022-03-29
Sentry.init({
  // eslint-disable-next-line prettier/prettier
  dsn: "https://c28699fc671e494e9c11c330ce8e4666@o1180456.ingest.sentry.io/6293273",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.HOST_URL,
});
 */

const AppLayout = dynamic(() => import("@/layouts/AppLayout"));

const App = ({ Component, pageProps }: AppProps) => {
  const { session } = pageProps;
  const { isEmptyLayout, enableScene, enableMap } = Component as any;
  const { everEnabledScene } = graphicsService((state) => ({
    everEnabledScene: state.enableScene,
  }));

  useEffect(() => {
    if (enableScene && !everEnabledScene)
      graphicsService.setState({ enableScene: true });
  }, [enableScene]);

  return (
    <>
      <Meta
        title={"PwC Virtual Spaces"}
        description={"PwC Virtual Spaces"}
        shareImage={""}
      />

      <GlobalStyle />
      <DatepickerStyles />

      <Clipaths />

      {isEmptyLayout ? (
        <EmptyLayout>
          <Component {...pageProps} />
        </EmptyLayout>
      ) : (
        <AuthProvider
          options={{
            keepAlive: 60 * 60 * 24, // Send keepAlive message every day
          }}
          session={session}
        >
          <AppLayout
            pageProps={pageProps}
            enableScene={everEnabledScene || enableScene}
            enableMap={enableMap}
          >
            <Component {...pageProps} />
          </AppLayout>
        </AuthProvider>
      )}
    </>
  );
};
export default App;
