import styled from "styled-components";

import Close from "./Close.svg";

const StyledClipPaths = styled.div`
  position: absolute;
`;

const Clipaths = () => {
  return (
    <StyledClipPaths>
      <Close />
    </StyledClipPaths>
  );
};

export default Clipaths;
