import Head from "next/head";

type TMeta = {
  title: string;
  description: string;
  shareImage: string;
};

const Meta = ({ title, description, shareImage }: TMeta) => {
  return (
    <Head>
      {/* Recommended Meta Tags */}
      <meta charSet="utf-8" />
      <meta name="language" content="english" />
      <meta httpEquiv="content-type" content="text/html" />
      {/* Search Engine Optimization Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="distribution" content="web" />
      <meta name="og:title" content={title} />
      <meta name="og:type" content="site" />
      <meta name="og:image" content={shareImage} />
      <meta name="og:site_name" content={title} />
      <meta name="og:description" content={description} />

      {/*  Favicons */}
      <link rel="icon" href="/meta/icon.ico" sizes="any" />
      <link rel="icon" href="/meta/icon.svg" type="image/svg+xml" />
      <link rel="apple-touch-icon" href="/meta/icon-180x180.png" />
      <link rel="manifest" href="/meta/manifest.json" />
      <link rel="apple-touch-icon" href="/meta/apple-touch-icon.png" />

      {/* Meta Tags for HTML pages on Mobile */}
      <meta name="format-detection" content="telephone=no" />
      <meta name="HandheldFriendly" content="true" />
      <meta
        name="viewport"
        content="width=device-width, minimum-scale=1, initial-scale=1.0"
      />
      <meta name="theme-color" content="#EB8C00" />
    </Head>
  );
};

export default Meta;
