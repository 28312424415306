import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import { variables } from "./variables";

import { colors } from "@/styles/colors";
import { customReset } from "@/styles/customReset";

const GlobalStyle = createGlobalStyle`
  ${normalize};
  ${customReset};
  ${variables};
  
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    //Scrollbar style fallback for firefox
    scrollbar-color: ${colors.grey.lighter.hex} transparent;
    scrollbar-width: thin;
  }
  
  html {
    overscroll-behavior: none;
    font-size: 62.5%;
    height: 100%;
  }
  html, html a {
    -webkit-text-size-adjust: none;
    text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.004);
  }
  
  body {
    color: ${colors.black.hex};
    line-height: 1.2;
    overscroll-behavior: none;
    font-family: var(--font-sans-serif);
    font-size: 1.6rem;
    min-height: 100%;

    &[data-theme="dark"] {
      color: ${colors.white.default};
    }
  }


  *::selection {
    color: ${colors.white.default};
    background: ${colors.orange.hex};
  }
  *::-moz-selection {
    color: ${colors.white.default};
    background: ${colors.orange.hex};
  }

  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
    overflow: hidden;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${colors.grey.lighter.hex};
  }
`;

export default GlobalStyle;
