//From https://easings.net/
export const ease = {
  //Default ease
  inCubic: "cubic-bezier(0.32, 0, 0.67, 0)",
  outCubic: "cubic-bezier(0.33, 1, 0.68, 1)",
  inOutCubic: "cubic-bezier(0.65, 0, 0.35, 1)",

  //Ease for bigger movements
  inQuint: "cubic-bezier(0.64, 0, 0.78, 0)",
  outQuint: "cubic-bezier(0.22, 1, 0.36, 1)",
  inOutQuint: "cubic-bezier(0.83, 0, 0.17, 1)",
};
