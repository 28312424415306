export const colors = {
  black: {
    hex: "#000000",
    rgb: "0, 0, 0",
  },
  green: {
    normal: {
      hex: "#22992E",
    },
    dark: {
      hex: "#2C8646",
    },
  },
  grey: {
    veryLight: {
      hex: "#E2E2E2",
    },
    diffAreas: {
      hex: "#E5E5E5",
    },
    lightest: {
      hex: "#F3F3F3",
    },
    lighter: {
      hex: "#CBCBCB",
    },
    light: {
      hex: "#979797",
    },
    low: {
      hex: "#3B3B3B",
      rgba: "59, 59, 59"
    },
    normal: {
      hex: "#707070",
    },
    dark: {
      hex: "#464646",
    },
    darker: {
      hex: "#191919",
      rgba: "25, 25, 25",
    },
    text: {
      hex: "#707070",
    },
  },
  orange: {
    hex: "#D04A02",
  },
  red: {
    light: {
      hex: "#F7C8C4",
    },
    normal: {
      hex: "#E0301E",
    },
  },
  rose: {
    hex: "#D93954",
  },
  tangerine: {
    light: {
      hex: "#f3ba66",
    },
    normal: {
      hex: "#EB8C00",
    },
  },
  white: {
    rgba: "255, 255, 255",
    default: "#FFF",
  },
  yellow: {
    hex: "#FFB600",
  },
  blue: {
    hex: "#365486",
  },
};

export const avatarColours = [
  {
    background: "#FFF0CC",
    text: "#BC860A",
  },
  {
    background: "#F7DDE1",
    text: "#DA536A",
  },
  {
    background: "#D3DDE4",
    text: "#486D8B",
  },
  {
    background: "#D4EBE9",
    text: "#26897E",
  },
];
