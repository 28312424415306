import { css } from "styled-components";

import { MQ } from "./mixins";

export const variables = css`
  :root,
  :before,
  :after {
    --font-serif: Georgia, "Times New Roman", Times, serif;
    --font-sans-serif: "Helvetica Neue", Helvetica, Arial, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", sans-serif;

    --button-radius: 0.5rem;
    --input-radius: 0.5rem;
    --input-chat-radius: 2rem;
    --checkbox-radius: 0.5rem;
    --map-grid-image-radius: 1rem;
    --video-window-radius: 1rem;
    --notification-radius: 1rem;
    --bubble-radius: 1rem;

    --mainNav-width: 7rem;
    --mainNav-panel-width: 21rem;
    --mainNav-panel-section-width: 18rem;
    --video-participants-list-width: 28rem;
    --mainNav-margin: 2rem;
    --mainNav-avatar-size: 3rem;
    --mainNav-border-radius: 1rem;
    --mainNav-overview-panel-padding: 2rem;
    --mainNav-overview-panel-border-radius: 1rem;
    --mainNav-logo: 2.4rem;
    --mainNav-left-spacing: calc(
      (var(--mainNav-width) - var(--mainNav-avatar-size)) / 2
    );

    --floating-profile-outer: 6rem;
    --floating-profile-inner: 4rem;

    --sidebar-panel-padding: 4rem;
    --sidebar-panel-border-radius: var(--mainNav-overview-panel-border-radius);
    --sidebar-panel-button-size: 2rem;

    --chat-panel-input-height: 4rem;

    --gradient-bottom-height: 5rem;
    --gradient-top-height: 5rem;
    --flex-grow-body-padding: var(--mainNav-overview-panel-padding);
    --avatar-options-width: 5.2rem;

    ${MQ.SM`
      --mainNav-panel-width: 24rem;
    `}

    ${MQ.LG`
      --mainNav-avatar-size: 4rem;
      --mainNav-logo: 3rem;
      --mainNav-width: 9rem;
      --mainNav-panel-width: 28rem;
      // --mainNav-border-radius: 4rem;
      --sidebar-panel-button-size: 3rem;
    `}
  }
`;
