import styled, { css } from "styled-components";

import { MQ } from "@/styles/mixins";

export const VeryBigHeadline = styled.h1<Record<any, any>>`
  font-size: 4rem;
  font-weight: ${(props) => (props.bold ? "700" : "300")};
  letter-spacing: 0.04rem;
  font-family: ${(props) =>
    props.serif ? "var(--font-serif)" : "var(--font-sans-serif)"};
`;

export const BigMidHeadline = styled.h2`
  font-size: 3rem;
  font-weight: ${(props) => (props.bold ? "700" : "300")};
  letter-spacing: 0.02em;
  font-family: ${(props) =>
    props.serif ? "var(--font-serif)" : "var(--font-sans-serif)"};
`;

export const BigHeadline = styled.h2`
  font-size: 1.5rem;
  font-weight: ${(props) => (props.bold ? "700" : "300")};
  letter-spacing: 0.04rem;
  font-family: ${(props) =>
    props.serif ? "var(--font-serif)" : "var(--font-sans-serif)"};
`;

export const MidHeadlineStyles = css`
  font-size: 1.1rem;
  font-weight: ${(props) => (props.light ? "300" : "700")};
  letter-spacing: 0.02rem;

  ${MQ.LG`
    font-size: 1.5rem;
  `}
`;

export const MidHeadline = styled.h3`
  ${MidHeadlineStyles};
`;

export const SmallHeadlineStyles = css`
  font-size: 0.9rem;
  font-weight: 700;

  ${MQ.MD`
    font-size: ${(props) => (props.size === "mid" ? "1.1rem" : "0.9rem")}
  `}

  ${MQ.LG`
    font-size: ${(props) => (props.size === "mid" ? "1.5rem" : "1.2rem")}
  `}
`;

export const SmallHeadline = styled.h4`
  ${SmallHeadlineStyles}
`;

export const TextStyles = css`
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: ${(props) => (props.bold ? "700" : props.light ? "300" : "400")};

  ${MQ.MD`
    font-size: ${(props) => (props.size === "mid" ? "1.1rem" : "0.9rem")};

    ${(props) =>
      !props.size &&
      css`
        letter-spacing: 0.02rem;
      `};
  `}

  ${MQ.LG`
    font-size: ${(props) => (props.size === "mid" ? "1.5rem" : "1.2rem")}
  `}
  }
`;

export const Text = styled.p<Record<any, any>>`
  ${TextStyles}
`;
