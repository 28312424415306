// Simple local storage helper based on:
// https://blog.logrocket.com/localstorage-javascript-complete-guide/

const localStorage = {
  set: (key: string, data: any) => {
    typeof window !== "undefined" &&
      window.localStorage.setItem(key, JSON.stringify(data));
  },

  get: (key: string) =>
    typeof window !== "undefined" &&
    JSON.parse(window.localStorage.getItem(key)!),

  remove: (key: string) => {
    typeof window !== "undefined" && window.localStorage.removeItem(key);
  },
};

export default localStorage;
