import create from "zustand";

import localStorage from "@/utils/LocalStorage";

type TGraphicsSettings = {
  framerate: number;
};

type TGraphicsService = {
  settings: TGraphicsSettings;
  enableScene: boolean;
  renderScene: boolean;
  updateSettings: (settings: TGraphicsSettings) => void;
  setRenderScene: (renderScene: boolean) => void;
};

const graphicsService = create<TGraphicsService>((set, get) => {
  const defaultSettings = {
    framerate: 60,
  };

  return {
    settings: localStorage.get("graphicsSettings") || defaultSettings,
    enableScene: false,
    renderScene: false,

    updateSettings: (settings) => {
      set((state) => ({
        settings: { ...state.settings, ...settings },
      }));

      localStorage.set("graphicsSettings", get().settings);
    },
    setRenderScene: (renderScene) => {
      set({ renderScene });

      return;
    },
  };
});

export default graphicsService;
