import { css } from "styled-components";

import { colors } from "@/styles/colors";
import { DarkTheme } from "./mixins";

export const customReset = () => css`
  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  html,
  li,
  ol,
  p,
  textarea,
  ul {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: ${colors.black.hex};
  }

  button {
    background-color: transparent;
    padding: 0;
    outline: none;
    cursor: pointer;
    color: ${colors.black.hex};

    ${DarkTheme(css`
      color: ${colors.white.default};
  `)}
  }

  a,
  button {
    touch-action: manipulation;
  }

  button,
  input,
  select,
  textarea {
    margin: 0;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
      outline: none;
    }
  }

  textarea {
    resize: none;
  }

  textarea:focus {
    outline-color: transparent;
    outline-style: none;
  }

  img,
  picture,
  video {
    width: 100%;
    height: auto;
    user-select: none;
    -webkit-user-drag: none;
  }

  svg {
    color: inherit;
    fill: currentColor;
  }

  dialog {
    border: 0;
    padding: 0;
  }
`;
