import { createGlobalStyle } from "styled-components";

import { colors } from "./colors";
import { SmallHeadlineStyles } from "./typography";

export default createGlobalStyle`
  .react-datepicker {
    ${SmallHeadlineStyles};
    background: transparent;
    border: 0;
    color: ${colors.white.default};
    font-family: var(--font-sans-serif);
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    ${SmallHeadlineStyles};
  }

  .react-datepicker__month-container,
  .react-datepicker__time-container {
    background: ${colors.grey.low.hex};
    background-clip: padding-box;
    border-radius: 1rem;
    border: 0.5rem solid rgba(59, 59, 59, 0.5);
    margin: 0;
    padding: 1.6rem;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-container
  {
  color: ${colors.white.default};
  font-family: var(--font-sans-serif)
  }

  .react-datepicker__header {
    background: transparent;
    border: 0;
    color: ${colors.white.default};
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: ${colors.grey.light.hex};
  }

  .react-datepicker__navigation {
    height: 3rem;
    top: 2.4rem;
    width: 3rem;
  }

  .react-datepicker__navigation-icon {
    left: unset;
    top: unset;
    width: unset;

    &::before {
      border-width: 0.1rem 0.1rem 0 0;
      height: 0.3rem;
      width: 0.3rem;
    }
  }


  .react-datepicker__day-names {
    padding: 0.5rem 0;
  }

  .react-datepicker__day-name {
    color: ${colors.white.default};
    margin-top: 0.4rem;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    box-sizing: content-box;
    margin: 0;
    padding: 0.8rem;
  }

  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${colors.white.default};
    font-weight: 400;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: ${colors.tangerine.normal.hex};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: transparent;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background-color: ${colors.grey.normal.hex};
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: ${colors.grey.normal.hex};
  }

  .react-datepicker__time {
    background: transparent;
  }

  .react-datepicker__time-container {
    margin-left: 0.3rem;
    max-height: 28rem;
    overflow: hidden;
    width: unset;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    border-radius: 0.5rem;
    font-weight: 400;
    height: auto;
    margin: auto;
    padding: 0;
    width: 4rem;
  }

  .react-datepicker__triangle {
    display: none;
  }
`;
